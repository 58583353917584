<script lang="ts">
	import { onMount } from 'svelte';

	onMount(() => {
		//@ts-ignore
		(window.adsbygoogle = window.adsbygoogle || []).push({});
	});
</script>

<svelte:head>
	<script
		async
		src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1567715774707131"
		crossorigin="anonymous"
	></script>
</svelte:head>

<div class="ads-container mb-4">
	<ins
		class="adsbygoogle"
		style="display:block"
		data-ad-client="ca-pub-1567715774707131"
		data-ad-slot="5840235916"
		data-ad-format="auto"
		data-full-width-responsive="true"
	/>
</div>

<style>
	ins {
		display: flex !important;
		flex-direction: row;
		justify-content: center;
	}
</style>
